import React from 'react'
import './404.css'

function NotFound() {
    return (
        <div class="custom-bg bg-light text-dark">
            <div class="d-flex align-items-center justify-content-center min-vh-100 px-2">
                <div class="text-center">
                    <h1 class="display-1 fw-bold not-found">404</h1>
                    <p class="fs-2 fw-medium mt-4">Oups ! Page introuvable</p>
                    <p class="mt-4 mb-5">La page que vous recherchez n'existe pas ou a été déplacée.</p>
                    <a href="/" class="btn btn-light fw-semibold rounded-pill px-4 py-2 custom-btn">
                        Retouner à l'accueil
                    </a>
                </div>
            </div>
        </div>
    )
}

export default NotFound