import React from 'react'
import './Report.css'

function Report() {
    return (
    <div className="container">
        <div className="d-flex justify-content-center mt-3 mb-3">
            {/* Fourni moi un emoji pour ce h1 */}
            <h3 className="fs-2">Pas de rapport disponible ! 🙁</h3>

        </div>
    </div>
    )
}

export default Report