import React from 'react';
import './Webinar.css'

function Webinar() {
    return (
        <div className="container">
        <div className="d-flex justify-content-center mt-3 mb-3">
            {/* Fourni moi un emoji pour ce h1 */}
            <h1 className="fs-2">Pas de webinar disponible ! 🙁</h1>
        </div>
    </div>
    );
}

export default Webinar