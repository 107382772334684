// src/components/Modal.js
import React from 'react';
import './Modal.css';
import { useState } from 'react';
import Swal from 'sweetalert2';

function Modal({ isOpen, onClose }) {

        // Déclaration des états
        const [name, setName] = useState('');
        const [email, setEmail] = useState('');
        const [firstName, setFirstName] = useState('');
        const [business, setBusiness] = useState('');
        const [industry, setIndustry] = useState('');
        const [message, setMessage] = useState('');

        const handleSubmit = async (event) => {
            event.preventDefault(); // Empêche le rechargement de la page
            // Validation côté client
            if (!name || !email || !firstName || !business || !industry || !message) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Champs requis',
                    text: 'Veuillez remplir tous les champs avant de continuer.',
                });
                return;
            }
            try {
                const response = await fetch('https://backend.actu24.cd/public/contact/us', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ name, email, firstName, business, industry, message }),
                });
                if (response.ok) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Message envoyé',
                        text: 'Merci de nous avoir contacté, nous vous contacterons dans les plus brefs délais.',
                    }).then(() => {
                        // Réinitialiser le formulaire
                        setName('');
                        setEmail('');
                        setFirstName('');
                        setBusiness('');
                        setIndustry('');
                        setMessage('');
                        // Redirection vers la page d'accueil
                        window.location.href = '/'; 
                    });
            } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Une erreur est survenue lors de l\'envoi du formulaire.',
                    });
                }
            } catch (error) {
                console.error(error);
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Une erreur est survenue lors de l\'envoi du formulaire.',
                });
            }
        };

    // Retourner null si le modal n'est pas ouvert
    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-container"> 
            <div className='modal-desc'>
                <span>Contactez-nous</span>
                <h2>Trouvez la solution adaptée à votre entreprise</h2>
                <p>Dans un monde en constante évolution, <br /> 
                nous sommes là pour vous aider à garder une <br /> 
                longueur d'avance avec les outils permettant de mesurer,  <br />
                de vous connecter et d'engager votre public.</p>
            </div>
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    &times;
                </button>
                <form onSubmit={handleSubmit}>
                    <div className="input-group">
                        <div>
                        <label>
                            Nom de famille :
                            <input 
                                value={name}
                                onChange={(e) => setName(e.target.value)}
                                placeholder='Nom de famille' 
                                type="text" 
                                name="name" 
                            />
                        </label>
                        </div>
                        <div>
                        <label>
                            Prénom :
                            <input 
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                                placeholder='Prénom' type="text" name="prenom" 
                            />
                        </label>
                        </div>
                    </div>
                    <label>
                        Adresse e-mail profetionnelle :
                        <input 
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='Email' 
                            type="email" 
                            name="email" 
                        />
                    </label>
                    <div className="input-group">
                        <div>
                        <label>
                            Entreprise :
                            <input 
                                value={business} 
                                onChange={(e) => setBusiness(e.target.value)} 
                                placeholder="Par exemple: 'data360degre'" 
                                type="text" 
                                name="business" 
                            />
                        </label>
                        </div>
                        <div>
                        <label>
                            Industrie :
                            <select 
                                value={industry} 
                                onChange={(e) => setIndustry(e.target.value)}
                                name="industry" 
                                id="industrie-select">
                                <option>Banque et Finance</option>
                                <option>Technologie</option>
                                <option>Energie</option>
                                <option>Logistique et Transport</option>
                                <option>Éducation</option>
                                <option>Pharmaceutique</option>
                                <option>Immobilier</option>
                                <option>Autres</option>
                            </select>
                        </label>
                        </div>
                    </div>
                    <label>
                        Message:
                        <textarea 
                            value={message} 
                            onChange={(e) => setMessage(e.target.value)} 
                            className='textarea' 
                            placeholder='(limite 255 caractère)' 
                            name="message">
                        </textarea>
                    </label>

                    <button  type="submit">Envoyer</button>
                </form>
            </div>
        </div>
        </div>
    );
}

export default Modal;
