import React from 'react';
import { Link } from 'react-router-dom';
import article from '../../../assets/images/arcticle-1.png'
import first_img from '../../../assets/images/first_img.png'
import second_img from '../../../assets/images/second_img.png'
import './Actu.css';
function New () {
    return (
        <div>
            <div className='news__articles'>            
                <div className='container  d-flex justify-content gap-5'>
            
                    <div className="card mb-3" style={{ maxWidth: '540px' }}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={first_img} className="img-fluid rounded-start" alt=""/>
                            </div>
                            <div className="first-article col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title text-white">Evénement</h5>
                                    <p className="card-text text-white">
                                    This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                    </p>
                                    <p className="card-text text-white">
                                    This is a wider card with supporting text
                                    </p>
                                </div>
                                <div className='btns-article d-flex justify-content-center gap-2'>
                                    <button>Publics</button>
                                    <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card mb-3" style={{ maxWidth: '540px' }}>
                        <div className="row g-0">
                            <div className="col-md-4">
                                <img src={first_img} className="img-fluid rounded-start" alt=""/>
                            </div>
                            <div className="first-article col-md-8">
                                <div className="card-body">
                                    <h5 className="card-title text-white">Evénement</h5>
                                    <p className="card-text text-white">
                                    This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.
                                    </p>
                                    <p className="card-text text-white">
                                    This is a wider card with supporting text
                                    </p>
                                </div>
                                <div className='btns-article d-flex justify-content-center gap-2'>
                                    <button>Publics</button>
                                    <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                                </div>
                            </div>
                        </div>
                    </div>


                    {/* <article className='news__article_last w-100 h-90 mt-5'> 
                        <div className='news__article-img'>
                            <img src={first_img} alt="" />
                        </div>
                        <div className='news__article-content'>
                            <div className='news__article-title'>
                                <h2>Acticle</h2>
                            </div>
                            <div className="news__article-title2">
                                <h3 className="text-white fs-5 mt-3 mb-3 fw-bold">Besoin de savoir : Pourquoi avons-nous besoin d'une mesure cross-média ?</h3>
                            </div>
                            <div className="news__article-desc">
                                <p className="text-white fs-10">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Fugit pariatur commodi, culpa sed consectetur praesentium. Soluta incidunt distinctio aut, illum possimus harum error labore earum exercitationem? Porro culpa debitis laudantium?</p>
                            </div>
                            <div className='news__article-buttons d-flex gap-2'>
                                <button>Publics</button>
                                <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                            </div>
                        </div>
                    </article>
                    <article className='news__article_second mt-5'>
                        <div className='news__article-img'>
                            <img src={second_img} alt="" />
                        </div>
                        <div className='news__article-content'>
                            <div className='news__article-title'>
                                <h2>Article</h2>
                            </div>
                            <div className="news__article-title2">
                                <h3>Besoin de savoir : Pourquoi avons-nous besoin d'une mesure cross-média ?</h3>
                            </div>
                            <div className='news__article-desc'>
                                <p>Besoin de savoir : Pourquoi avons-nous besoin d'une mesure cross-média ?</p>
                            </div>
                            <div className='news__article-buttons'>
                                <button>Publics</button>
                                <button>Pulié le| 12/10/2024</button>
                            </div>
                        </div>
                    </article> */}
                </div>

            </div>
            <div className='news__articles'>
                <div className='container d-flex justify-content gap-5'>
                    <div className="card" style={{ width: '18rem' }}>
                        <img  src={second_img} className="card-img-top" alt="..." />
                        <div className="one-card card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            </p>
                            <div className='btns-article d-flex justify-content-center gap-2'>
                                <button>Publics</button>
                                <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{ width: '18rem' }}>
                        <img  src={second_img} className="card-img-top" alt="..." />
                        <div className="one-card card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            </p>
                            <div className='btns-article d-flex justify-content-center gap-2'>
                                <button>Publics</button>
                                <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{ width: '18rem' }}>
                        <img  src={second_img} className="card-img-top" alt="..." />
                        <div className="one-card card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            </p>
                            <div className='btns-article d-flex justify-content-center gap-2'>
                                <button>Publics</button>
                                <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                            </div>
                        </div>
                    </div>

                    <div className="card" style={{ width: '18rem' }}>
                        <img  src={second_img} className="card-img-top" alt="..." />
                        <div className="one-card card-body">
                            <h5 className="card-title">Card title</h5>
                            <p className="card-text">
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            </p>
                            <div className='btns-article d-flex justify-content-center gap-2'>
                                <button>Publics</button>
                                <button><i class="far fa-clock"></i> Pulié le| 12/10/2024</button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
            <section className='container'>
                <div className='container mt-5 mb-3'>
                    <div className=''>
                        <h2 className='fw-bold fs-3'>Toutes nos publications</h2>
                </div>
                </div>
                    <div>
                        <div className='last-article mt-3'>
                            <div className='img-last'>
                                <Link to='/News/detail'>
                                    <img className='img__last-img' src={article} alt="" />
                                </Link>
                            </div>&nbsp;
                            <div className='title-last'>
                            <h6>Publié | 09 janvier 2025</h6>
                            <h2>Les études de Marché en Mutation...</h2>
                                <p>      
                                La RDC, un pays longtemps marqué par des défis logistiques et infrastructurels, 
                                voit aujourd'hui son paysage marketing transformé par une vague digitale...</p>
                            </div>
                        </div>
                        <hr className='article-insgth-hr' />
                    </div>
            </section>
            {/* <div className='container d-flex justify-content-center mb-3'>
                <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-center">
                        <li class="page-item disabled">
                        <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                        </li>
                        <li class="page-item"><a class="page-link" href="#">1</a></li>
                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                        <li class="page-item"><a class="page-link" href="#">...</a></li>
                        <li class="page-item"><a class="page-link" href="#">5</a></li>
                        <li class="page-item"><a class="page-link" href="#">6</a></li>
                        <li class="page-item">
                        <a class="page-link" href="#">Next</a>
                        </li>
                    </ul>
                </nav>
            </div> */}
            <section className='section-community'>
                <div className='container d-flex justify-content-between gap-5'>
                    <div className='community'>
                        <div className='community__title d-flex justify-content-start mb-2'>
                            <h3 className='pt-5 color-white'>Rejoignez notre communauté</h3>
                        </div>
                        <div className='community__desc d-flex justify-content-start'>
                            <p className='color-white'>Tenez-vous informé des dernières actualités grâce à notre newsletter hebdomadaire</p>
                        </div>
                    </div>
                    <div className='community__form'>
                        <div className='community__form-input'>
                            <div>
                                <input type="text" placeholder="Nom" />
                            </div>
                            <div>
                                <input type="text" placeholder="Email" />
                            </div>
                            <div className='community__form-submit d-flex justify-content-end mt-2 mb-2'>
                                <button>S'abonner</button>
                            </div>
                        </div>
                        <div className='community__form-desc'>
                            <div>
                                <p className='text-white'>En cliquant sur "S'abonner", j'accepte l'avis de confidentialité et les conditions d'utilisation de data360degree.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default New;