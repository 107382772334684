import React, { useState } from "react";
import { FaPlus, FaMinus } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Faq.css'; // Assurez-vous que le fichier CSS est bien importé

const FAQ = () => {
  const faqData = [
    {
      question: "Comment utiliser ce site ?",
      answer: "Pour utiliser ce site, il vous suffit de naviguer à travers les différentes sections via le menu."
    },
    {
      question: "Comment puis-je créer un compte ?",
      answer: "Cliquez sur le bouton 'S'inscrire' en haut à droite et suivez les instructions."
    },
    {
      question: "Puis-je changer mes informations de profil ?",
      answer: "Oui, vous pouvez modifier vos informations de profil en allant dans la section 'Mon compte'."
    },
  ];

  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null);
    } else {
      setOpenIndex(index);
    }
  };

  return (
    <div class="container">
      <div className="faq-container">
      {faqData.map((faq, index) => (
        <div key={index} className="faq-item">
          <div 
            className="faq-question" 
            onClick={() => toggleFAQ(index)}
          >
            <span>{faq.question}</span>
            <span>
              {openIndex === index ? <FaMinus /> : <FaPlus />} {/* Icône dynamique */}
            </span>
          </div>
          {openIndex === index && (
            <div className="faq-answer">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
    {/* <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="accordion" id="modernAccordion">
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Accordion Item #1
                            </button>
                    </h2>
                    <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#modernAccordion">
                        <div class="accordion-body">
                            This is the first item's accordion body. It is shown by default, until the collapse plugin
                            adds the appropriate classes that we use to style each element.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Accordion Item #2
                            </button>
                    </h2>
                    <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#modernAccordion">
                        <div class="accordion-body">
                            This is the second item's accordion body. It is hidden by default, until the collapse plugin
                            adds the appropriate classes that we use to style each element.
                        </div>
                    </div>
                </div>
                <div class="accordion-item">
                    <h2 class="accordion-header">
                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Accordion Item #3
                            </button>
                    </h2>
                    <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#modernAccordion">
                        <div class="accordion-body">
                            This is the third item's accordion body. It is hidden by default, until the collapse plugin
                            adds the appropriate classes that we use to style each element.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> */}
    </div>
  );
};

export default FAQ;
