import React, { useState, useEffect, Fragment } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import '../../App.css';
import '../../global.css';
import TabContent1 from '../../components/TabContent1';
import TabContent2 from '../../components/TabContent2';
import Hero1 from '../../assets/images/AUTO.png';
import data from '../../assets/images/drowp-menu.png';
import pub from '../../assets/images/close-up-woman-being-surprised.jpg';
import Hero2 from '../../assets/images/violet.png';
import Caem from '../../assets/images/parthner/caem.png';
import Acu24 from '../../assets/images/parthner/systemage.png';
import FatsiTeam from '../../assets/images/parthner/fatshi.png';
import Ndunga from '../../assets/images/parthner/optimus.png';
import Voda from '../../assets/images/voda.png';
import Orange from '../../assets/images/orang.png';
import MathInfo from '../../assets/images/parthner/congo.png';
import { Link } from 'react-router-dom';
import Science from '../../assets/images/ScienceD.png';
import Search from '../../assets/images/search.png';

const heroData = [
    {
        h1: (  
            <>Boostez l'efficacité de votre entreprise avec l'automatisation des tâches</>  
        ),  
        description: 'Réduisez les erreurs, gagnez du temps et concentrez-vous sur l\'essentiel grâce à nos solutions sur mesure.',
        imageUrl: Hero1,
        backgroundColor: '#0faccf',
        button: 'En savoir plus',
    },
    {
        h1: (  
            <>Veille Média </>  
        ),  
        description: "Grâce à notre veille médiatique efficace, protégez votre image, valorisez votre marque et optimisez votre communication.",
        imageUrl: Hero2,
        backgroundColor: '#7f0783',
        button: 'En savoir plus',
    }, 
    {
        h1: (  
            <>Recherche marketing & Etude de marché </>  
        ),  
        description: "Transformez vos idées en succès grâce à notre expertise. Nous vous guidons avec des données précises, des analyses approfondies et un suivi régulier des KPI pour optimiser vos prises de décision.",
        imageUrl: Search,
        backgroundColor: '#110130',
        button: 'En savoir plus',
    },
    {
        h1: (  
            <>Science de la population</>  
        ),  
        description: "Forts de notre expertise et de notre connaissance approfondie du Congo profond, nous vous offrons les outils pour comprendre les dynamiques humaines et prendre des décisions éclairées.",
        imageUrl: Science,
        backgroundColor: '#2E037C',
        button: 'En savoir plus',
        // button : 'Telecharger brochure',
    }
];
function Home() {
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % heroData.length);
        }, 3000); // Change every 10 seconds

        return () => clearInterval(interval);
    }, []);

    const currentHero = heroData[currentIndex];
    const [activeTab, setActiveTab] = useState('tab1');

    // visibility state section
    const [isVisible, setIsVisible] = useState(false);  
      // Fonction pour changer l'état de visibilité  
    const toggleVisibility = () => {  
        setIsVisible(!isVisible);  
    };  

    return (
        <main>
                <section 
                    className="full-width-bg hero-section" 
                    style={{ backgroundColor: currentHero.backgroundColor }}
                >
                    <div className="container d-flex justify-content-between">
                        <div className="hero-desc">
                            <h1>{currentHero.h1}</h1> 
                            <p>{currentHero.description}</p>
                            <div className='d-flex gap-3'>
                                <Link to="/solutions">
                                    <button>{currentHero.button}</button>
                                </Link>
                                <button>Télécharger brochure</button>
                            </div>
                        </div>
                        <figure className="hero-imgs">
                            <img src={currentHero.imageUrl} alt="Hero" />
                        </figure>
                    </div>
                </section>
              {/* Section 2 tabs */}
                <section className="full-width-bg second-section">
                    <div className='container'>
                        <div className="tabs">
                        <div className="tab-buttons">
                            <button onClick={() => setActiveTab('tab1')} className={activeTab === 'tab1' ? 'active' : ''}>
                            Articles récents
                            </button>&nbsp;&nbsp;
                            <button onClick={() => setActiveTab('tab2')} className={activeTab === 'tab2' ? 'active' : ''}>
                            Evénements 
                            </button>&nbsp;&nbsp;
                        </div>
                        <div className="tab-content">
                            <TransitionGroup>
                            <CSSTransition key={activeTab} timeout={300} classNames="fade">
                                {activeTab === 'tab1' ? <TabContent1 /> : activeTab === 'tab2' ? <TabContent2 /> : null}
                            </CSSTransition>
                            </TransitionGroup>
                        </div>
                        </div>
                    </div>
                </section>
            
                <div className='container hero2-section'>
                    <div className='hero2-desc'>
                        <span>Nos méthodologies de recherche</span>
                        <h3>Data360degree</h3>
                        <p>Découvrez comment nos solutions innovantes peuvent propulser <br />votre entreprise vers de nouveaux sommets.</p>
                        <Link to={'/solutions'}>
                            <button>Apprendre encore plus</button>
                        </Link>
                    </div>
                    <figure className='hero2-img'>
                        <img src={data} alt="" />
                    </figure>
                </div>
                <div>
                <div className='section-card'>
                    <div className='container d-flex justify-content-between gap-3'>
                        <div className='card-block'>
                            <h2 className='title-card1'>Recherche marketing</h2>
                            <p>La recherche marketing est essentielle pour comprendre votre marché, anticiper les tendances et prendre des décisions éclairées...</p>
                            <Link style={{ textDecoration: 'none'}} className='d-flex justify-content-center' to="/solutions">
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block'>
                            <h2 className='title-card2'>Science de population</h2>
                            <p>Ce champ de recherche englobe une multitude de disciplines, dont la démographie, la sociologie, l'économie, la géographie...</p>
                            <Link style={{ textDecoration: 'none'}}  className='d-flex justify-content-center' to='/solutions'>
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block data'>
                            <h2 className='title-card3'>DATA Consulting </h2>
                            <p>Les données sont au cœur de la prise de décision moderne. Chez DATA360, nous vous aidons à exploiter pleinement la puissance de vos données grâce à nos solutions...</p>
                            <Link style={{ textDecoration: 'none'}}  className='d-flex justify-content-center' to="/solutions">
                                <button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                        <div className='card-block strategic'>
                            <h2 className='title-card4'>Veille Stratégique</h2>
                            <p>Surveillez votre environnement d'affaires et restez informé des tendances et des évolutions qui peuvent impacter votre entreprise...</p>
                            <Link  style={{ textDecoration: 'none'}} className='d-flex justify-content-center' to='/solutions'>
                            <   button className='mb-3'>Explorer</button>
                            </Link>
                        </div>
                    </div>
                </div>
                
                <div className='btn-container mb-5'>
                    <Link to={'/solutions'}>
                        <button>Voir toute les solutions</button>
                    </Link>
                    </div>
                </div>

                <section className="pub-section mb-5">
                    <div className="container title">
                        <h5 className='text-white'>Veille stratégique</h5>
                        <h2 className='pub-section-title'>Nous mettons a votre disposition NUNGA SCAN MEDIAS, 
                        une plateforme intelligente de veille médiatique, veille concurencielle, 
                        veille technologique et de pige.
                        </h2>
                    </div>
                    <div className="container pub-container">
                        <div className="pub-desc">
                            <span className=''>NUNGA scan médias</span>
                            <h2 className='mt-4'>Obtenez des rapports personalisé sur la veille Stratégique</h2>
                            <h6>Surveillez votre environnement d'affaires et restez informé des tendances et des évolutions qui peuvent impacter votre entreprise.</h6>
                            <p>Nous mettons a votre service une veille stratégique pour une prise de décision proactive et efficace graâce à nos rappports hébdomadaire, mensuel et annuel.</p>
                            <div className='btns-article d-flex justify-content-start gap-2'>
                                <button>voir plus</button>
                                <button><i class="far fa-clock"></i> Voir les rapports</button>
                            </div>
                        </div>
                        <figure>
                            <img src={pub} alt="" />
                        </figure>
                    </div>
                    <div>
                        {/* <button>Voir toute les informations</button> */}
                    </div>
                </section>
            
            <section className="section-personalize mb-5" >
                <div className='container title-personalize mb-5'>
                    <span className='mt-5'>Nos réalisations</span>
                    <h2>Nous sommes au cœur d’un changement positif au sein des entreprises, rendu possible grâce à des études de marché innovantes réalisées pour nos clients.
                    </h2>
                </div>
                <hr className='container'/>
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >Usage et Attitude</h2>                        
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                        L'étude Usage et Attitude est une méthode de recherche qui examine à la fois comment les consommateurs utilisent un produit ou un service (Usage) et ce qu'ils pensent de celui-ci (Attitude). 
                        Elle combine des aspects de recherche qualitative et quantitative pour fournir une vision holistique du comportement et de la perception des consommateurs.
                        </p>
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                        L'étude Usage et Attitude est une méthode de recherche qui examine à la fois comment les consommateurs utilisent un produit ou un service (Usage) et ce qu'ils pensent de celui-ci (Attitude). 
                        Elle combine des aspects de recherche qualitative et quantitative pour fournir une vision holistique du comportement et de la perception des consommateurs.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >Etude sur le service financier mobile (SFM) en anglais (MFS)</h2>                        
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                            Les études sur le service financier mobile se concentrent sur l'impact, l'adoption, et les retombées économiques et sociales des services financiers accessibles via les téléphones mobiles. 
                            Ces services incluent mais ne se limitent pas aux paiements mobiles, à l'épargne mobile, au crédit mobile, et à l'assurance mobile.
                        </p>
                    </div>
                    <div className='block-text '>
                        <p className='block-text_ds'>
                        Inclusion financière, Impact Éconmique Adoption et Usage, innovation Sécurité, Évaluation de L'Efficacité.            
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >Trade Census (Retail census)</h2>                        
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                            Recensement de tout les points de ventes qui vendent les produits ciblés. 
                            Un "Trade Census" ou "Retail Census" est une enquête exhaustive visant à recueillir des données détaillées sur les établissements de commerce de détail dans une région donnée. Cela inclut généralement la collecte d'informations sur le nombre d'entreprises, leur localisation, 
                            le type de commerce, les ventes annuelles, l'emploi, et d'autres paramètres économiques pertinents.
                        </p>
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                            Cartographier le paysage du commerce de détail, Analyser les tendances économiques, Aider à la planification et à la prise de décision, Évaluer l'impact économique, Benchmarking.
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >Opportunite, Prospection</h2>                        
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                            Cette étude, souvent synonyme de "business case" ou "note d'opportunité", est une analyse approfondie menée avant le lancement d'un projet pour évaluer sa viabilité économique, son alignement avec les objectifs stratégiques de l'organisation, et son potentiel de création de valeur. La prospection, dans ce contexte, se réfère à l'identification et l'évaluation des opportunités commerciales potentielles, qu'elles soient liées à de nouveaux marchés, produits, services, ou stratégies.
                        </p>
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                        Validation du Projet Priorisation des Initiatives Évaluation des Opportunités Analyse des Risques Justification de l'Investissement Planification Stratégique                        
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>
                <hr className='container' />
                <div className='container container-personalize mt-1'>
                    <div className='block-750'>
                        <h2 >Brand tracker</h2>                        
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                            Le Brand tracking ou suivi de marque est une méthode de recherche continue qui surveille la performance d'une marque au fil du temps. Elle mesure divers indicateurs clés de performance (KPIs) tels que la notoriété de la marque, la perception, la fidélité, l'usage, et la considération. Ces études utilisent généralement des enquêtes récurrentes pour capturer les changements dans les perceptions et comportements des consommateurs envers une marque.                        
                        </p>
                    </div>
                    <div className='block-text'>
                        <p className='block-text_ds'>
                        Surveillance de la Santé de la Marque, Évaluation de l'Efficacité des Campagnes, Comparaison Concurrentielle, Détection des Tendances, Prendre des Décisions Informatives, Optimisation des Ressources 
                        </p>
                    </div>
                    <div className='block-button'>
                        <button>Voir plus</button>
                    </div>
                </div>

                <div className="shore-more-container d-flex justify-content-center">
                    <button className='shore-more-container_button' onClick={toggleVisibility}>  
                        {isVisible ? 'Masquer le contenu' : 'Afficher plus de contenu'}  
                    </button>  
                </div>

                {isVisible && (
                    <Fragment className={`hidden-content ${isVisible ? 'fade-in' : 'fade-out'}`}>
                    <hr className='container' />
                    <div className='container container-personalize mt-1'>
                        <div className='block-750'>
                            <h2>Mystery Shopping </h2>
                        </div>
                        <div className='block-text'>
                            <p className='block-text_ds'>
                                Le Mystery Shopping, ou "client mystère" en français, est une méthode de recherche où des individus, appelés "mystery shoppers" (clients mystères), sont envoyés par une entreprise pour agir en tant que clients réguliers dans le but d'évaluer la qualité du service, l'expérience client, et parfois la conformité avec les normes de l'entreprise. Ces études peuvent se dérouler en magasin, au téléphone, ou en ligne.
                            </p>
                        </div>
                        <div className='block-text'>
                            <p className='block-text_ds'>
                                Évaluation de la Qualité de Service, Identification des Points d'Amélioration, Concurrence Benchmarking, Formation et Motivation des Employés, Vérification de la Cohérence, Analyse de l'Expérience Client
                            </p>
                        </div>
                        <div className='block-button'>
                            <button>Voir plus</button>
                        </div>
                    </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Mystery Calling</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Le Mystery Calling, ou "appel mystère", est une variante du Mystery Shopping où des évaluateurs anonymes appellent une entreprise en se faisant passer pour des clients pour évaluer la qualité du service clientèle par téléphone. Cette méthode permet de tester l'efficacité, la politesse, et la compétence des agents de centre d'appels ou des employés qui gèrent les appels téléphoniques.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Évaluation de la Qualité du Service Téléphonique, Amélioration de l'Expérience Client, Vérification de la Conformité, Benchmarking, Détection des Problèmes
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Etude Barometrique</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Une étude barométrique publicitaire est une enquête périodique conçue pour mesurer et suivre l'évolution de divers indicateurs liés à la publicité et au marketing au fil du temps. Elle se distingue par sa répétitivité et son objectif de fournir des données comparatives sur des périodes spécifiques. Ces études peuvent se concentrer sur l'efficacité des campagnes publicitaires, la perception des consommateurs vis-à-vis des publicités, ou encore sur les tendances dans les dépenses publicitaires et les stratégies de médias.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Mesure de l'Efficacité Publicitaire, Suivi des Tendances du Marché, Analyse de l'Image de Marque, Comparaison Concurrentielle, Ajustement des Stratégies, Évaluation de la Satisfaction Publicitaire
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Advertising</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    L'étude Advertising (étude publicitaire) se concentre sur l'évaluation de l'efficacité, de l'impact et des stratégies des campagnes publicitaires. Cela inclut l'analyse de la perception des publicités par les consommateurs, l'efficacité des différents canaux publicitaires, la mesure de la notoriété de marque induite par la publicité, et l'évaluation du retour sur investissement publicitaire (ROAS).
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Mesure de l'Efficacité Publicitaire, Optimisation des Campagnes, Analyse des Canaux Publicitaires, Benchmarking Publicitaire, Étude de l'Engagement, Segmentation et Ciblage
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Distribution de produits</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Cette étude se concentre sur l'analyse des canaux, méthodes et stratégies utilisées pour mettre les produits ou services à la disposition des consommateurs finaux. Elle examine comment les entreprises distribuent leurs produits, les défis rencontrés, les coûts associés, et les effets sur la satisfaction des clients et la performance de l'entreprise.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Analyse des Canaux de Distribution, Optimisation des Stratégies de Distribution, Évaluation de l'Impact Économique, Compréhension des Comportements d'Achat, Adaptation aux Tendances du Marché, Évaluation de la Logistique
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Etude Médiatique</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Cette étude médiatique se concentre sur l'analyse de la manière dont les médias traitent, représentent, et influencent la perception du public sur divers sujets. Elle examine les pratiques médiatiques, l'impact des médias sur la société, les stratégies de communication, et la réception des messages médiatiques. Ces études peuvent couvrir la presse écrite, la radio, la télévision, les médias numériques, et les réseaux sociaux.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Analyse de la Représentation Médiatique, Évaluation de l'Impact Médiatique, Étude des Stratégies de Communication, Surveillance de la Médiatisation, Éducation aux Médias, Évaluation de l'Efficacité des Campagnes
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Etude technologique (FTTH et FTTX)</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    FTTH (Fiber to the Home) et FTTX (Fiber to the x) sont des termes utilisés pour décrire les différentes architectures de déploiement de la fibre optique pour fournir des services de télécommunications à haut débit. FTTH désigne spécifiquement le raccordement de la fibre optique jusqu'au domicile des particuliers, tandis que FTTX est un terme générique pour toutes les configurations où la fibre optique est utilisée jusqu'à un certain point proche de l'utilisateur final, comme le bâtiment (FTTB), le quartier (FTTN), ou le trottoir (FTTC).
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Comparaison des Technologies, Analyse de la Faisabilité, Étude de Marché, Impact sur les Services, Optimisation des Réseaux, Évaluation de l'Infrastructure
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Product testing ou (test de produit)</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Le test de produit, ou product testing, est un processus de recherche où les produits sont évalués par des consommateurs ou des experts avant leur lancement sur le marché ou après des modifications significatives. L'objectif est de recueillir des retours sur la qualité, l'usage, la fonctionnalité, l'acceptabilité, et parfois l'innovation du produit. Cela peut inclure des tests en laboratoire, des tests de marché, ou des tests par des consommateurs dans des conditions réelles ou simulées.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Évaluation de la Qualité, Feedback Consommateur, Optimisation du Produit, Validation du Concept, Comparaison Concurrentielle, Réduction des Risques
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><hr className='container' /><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Market Sizing</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Le market sizing, ou estimation de la taille du marché, vise à déterminer la taille totale d'un marché spécifique, généralement exprimée en termes de volume (unités vendues) ou de valeur (revenus générés). Cette estimation peut être utilisée pour comprendre le potentiel de marché pour un produit ou service donné, évaluer les opportunités de croissance, et planifier des stratégies d'affaires.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Détermination de la Taille du Marché, Évaluation des Opportunités, Planification Stratégique, Benchmarking, Aide à la Décision, Analyse de la Compétitivité
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div><div className='container container-personalize mt-1'>
                            <div className='block-750'>
                                <h2>Market Sizing</h2>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Le market sizing, ou estimation de la taille du marché, vise à déterminer la taille totale d'un marché spécifique, généralement exprimée en termes de volume (unités vendues) ou de valeur (revenus générés). Cette estimation peut être utilisée pour comprendre le potentiel de marché pour un produit ou service donné, évaluer les opportunités de croissance, et planifier des stratégies d'affaires.
                                </p>
                            </div>
                            <div className='block-text'>
                                <p className='block-text_ds'>
                                    Détermination de la Taille du Marché, Évaluation des Opportunités, Planification Stratégique, Benchmarking, Aide à la Décision, Analyse de la Compétitivité
                                </p>
                            </div>
                            <div className='block-button'>
                                <button>Voir plus</button>
                            </div>
                        </div>
                    </Fragment>
                )}  
            </section>
                {/* Section des Partenaires */}
            <section className="partners">
                    <h2>Nos Partenaires</h2>
                <div className='container d-flex justify-content-center'>
                    <div className="partners-container">
                        <div className="partner">
                            <img  src={Caem} alt="Logo du partenaire 1" />
                        </div>
                        <div className="partner">
                            <img src={MathInfo} alt="Logo du partenaire 2" />          
                        </div>
                        <div className="partner">
                            <img src={Acu24} alt="Logo du partenaire 3" />          
                        </div>
                        <div className="partner">
                            <img src={Voda} alt="Logo du partenaire 4" />          
                        </div>
                        <div className="partner">
                            <img src={Orange} alt="Logo du partenaire 5" />
                        </div>
                        <div className="partner">
                            <img src={FatsiTeam} alt="Logo du partenaire 6" />
                        </div>
                        <div className="partner">
                            <img src={Ndunga} alt="" />
                        </div>
                    </div>
                </div>
            </section>
        </main>
                
    );
}

export default Home;
