import React from "react";
import { Link } from 'react-router-dom';
import article from '../../../assets/images/arcticle-1.png'

function Article() {
    return (
        <div className=" container d-flex justify-content-center mt-3">
                <div>
                    <div className='last-article mt-5'>
                        <div className='img-last'>
                            <Link to='/News/detail'>
                                <img className='img__last-img' src={article} alt="" />
                            </Link>
                            </div>&nbsp;
                            <div className='title-last'>
                            <h6>Publié | 09 janvier 2025</h6>
                            <h2>Les études de Marché en Mutation...</h2>
                                <p>      
                                La RDC, un pays longtemps marqué par des défis logistiques et infrastructurels, 
                                voit aujourd'hui son paysage marketing transformé par une vague digitale...</p>
                            </div>
                        </div>
                    <hr className='article-insgth-hr' />
                </div>
        </div>  
    );
}

export default Article;