import React from "react";
import './Event.css' 

function Event() {
    return (
    <div className="container">
        <div className="d-flex justify-content-center mt-3 mb-3">
            {/* Fourni moi un emoji pour ce h1 */}
            <h3 className="fs-2">Aucun événement disponible pour l’instant ! 🙁</h3>
        </div>
    </div>
    );
}

export default Event;