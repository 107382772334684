import React from 'react';
import './Footer.css'; 
import Modal from '../../components/Modal';
import { useState } from 'react';
import Swal from 'sweetalert2';

const Footer = () => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const handleNewsletter = async (event) => {
      event.preventDefault(); // Empêche le rechargement de la page

      // Validation côté client
      if (!email || !name) {
        Swal.fire({
          icon: 'warning',
          title: 'Champs requis',
          text: 'Veuillez remplir tous les champs avant de continuer.',
        });
        return;
      }

      try {
        const response = await fetch('https://backend.actu24.cd/public/api/newsletter', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ email, name }),
        });

        if (response.ok) {
          Swal.fire({
            icon: 'success',
            title: 'Inscription réussie',
            text: 'Vous avez bien souscrit à notre newsletter!',
          });
          setEmail(''); // Réinitialise les champs
          setName('');
        } else {
          const errorData = await response.json();
          Swal.fire({
            icon: 'error',
            title: 'Erreur',
            text: errorData.message || 'Une erreur est survenue lors de l\'inscription à la newsletter.',
          });
        }
      } catch (error) {
        console.error('Erreur lors de l\'inscription :', error);
        Swal.fire({
          icon: 'error',
          title: 'Une erreur est survenue',
          text: 'Impossible de se connecter au serveur. Veuillez réessayer plus tard.',
        });
      }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <footer className="footer">
      <div className="container footer-suscribe mt-5 mb-5">
        <div className="footer-title">
          <h2><strong>Besoin d'aide <br /> ou de plus </strong> d'informations ?</h2>
          <p>Tenez-vous au courant des nos activités, grâce à notre newsletter.</p>
        </div>
        <div className="footer-form">
          <form  onSubmit={handleNewsletter} name="newsletter" action="#">
            <label className='newsletter' htmlFor="">Newsletter</label>
            <input id="newsletter-email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} className='text-center' type="email" placeholder="Votre adresse email" required />
            <input id="newsletter-name" name="name" value={name} onChange={(e) => setName(e.target.value)} className='text-center' type="text" placeholder="Votre nom" required />
            <button className='mt-2' type="submit">S'inscrire</button>
          </form>
        </div>
      </div>
      <hr className="container" />
      <div className="container footer-content">
        <div className="footer-links">
            <div className='d-flex justify-content-between gap-1'>
                <div>
                    <p className=''><a style={{color: '#0FACCF'}} className='text-decoration-none fs-6'  href="/about">A propos</a></p>
                </div>|
                  <div>
                  <p className=''><a style={{color: '#0FACCF'}} className='text-decoration-none fs-6'  href="#">carrière</a></p>
                </div>|
                <div>
                  <p className=''><a onClick={toggleModal} style={{color: '#0FACCF'}} className='text-decoration-none fs-6'  href="#">Contact</a></p>
                </div>
            </div>
        </div>
        <div className="footer-social">
          <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-facebook-f"></i></a>
          <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-instagram"></i></a>
          <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin-in"></i></a>
          <a href="https://www.x.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-x"></i></a>
          <a href="https://www.youtube.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-youtube"></i></a>
          <a href="https://www.whatsapp.com" target="_blank" rel="noopener noreferrer"><i className="fab fa-whatsapp mb-1"></i></a>
          <div className="">
            <div className='mt-2'>
              <p>63, avenue Colonel Mondjiba, Silikin Village, Phase 3</p>
            </div>
            <a className='text-decoration-none fs-6' href="#">Politique de confidentialité</a>|
            <a className='text-decoration-none fs-6' href="#">Conditions d'utilisation</a>
          <p className='fs-6'>&copy; Data360degree 2024. Tous droits réservés.</p>
        </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </footer>
  );
}; 
export default Footer;
