import React from 'react';
import { useState } from 'react';
import Modal from '../../../components/Modal';
import './StrategicWatch.css';
import imgStrategic from '../../../assets/images/media.png'
import rapportImg from '../../../assets/images/Group 56.png'
import brochure from '../../../assets/Brochures/veilleM.pdf';
import Swal from 'sweetalert2';

function StrategicWatch() {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const showAlert = () => {
      Swal.fire({
          title: 'Désolé!',
          text: " Pas de rapport disponible pour l'instant ",
          icon: 'info',
          confirmButtonText: 'OK'
      });
  };
  const handleSubscribe = async () => {  
              const { value } = await Swal.fire({  
                  title: 'Veuillez laisser vos informations pour télécharger cette brochure',  
                  html: `  
                      <input id="swal-input1" class="swal2-input" style="width: 100%; max-width: 400px;" placeholder="Votre prénom">  
                      <input id="swal-input2" class="swal2-input"  style="width: 100%; max-width: 400px;" placeholder="Votre nom">  
                      <input id="swal-input3" type="email" class="swal2-input"  style="width: 100%; max-width: 400px;" placeholder="Votre adresse email">  
                  `,  
                  focusConfirm: false,  
                  showCancelButton: true,  
                  confirmButtonText: 'Valider',  
                  cancelButtonText: 'Annuler',
                  customClass: {  
                      title: 'swal-title', // Classe pour le titre  
                      input: 'swal-input'  // Classe pour les inputs  
                  },    
                  preConfirm: () => {  
                      const email = document.getElementById('swal-input3').value;  
                      const firstname = document.getElementById('swal-input1').value;  
                      const name = document.getElementById('swal-input2').value;  
          
                      if (!email) {  
                          Swal.showValidationMessage('Veuillez entrer une adresse email!');  
                      }  
                      return { email, firstname, name };  
                  },
                  onBeforeOpen: () => {  
                      // Appliquer le style à l'élément titre  
                      const titleElement = document.querySelector('.swal-title');  
                      if (titleElement) {  
                          titleElement.style.fontSize = '10px'; // Ajustez à la taille souhaitée  
                          titleElement.style.marginBottom = '10px'; // Marge en bas si nécessaire  
                          titleElement.style.fontWeight = 'normal'; // Ajuster le poids de la police si besoin  
                      }  
                  },  
              });   
          
              // Vérifiez si value est défini avant de déstructurer  
              if (value) {  
                  const { email, firstname, name } = value;  
                  console.log("Informations saisies:", { email, firstname, name }); // Vérifiez ici  
          
                  if (email) {  
                      try {  
                          const response = await fetch('https://backend.actu24.cd/public/newsletter/subscriber', {  
                              method: 'POST',  
                              headers: {  
                                  'Content-Type': 'application/json',  
                              },  
                              body: JSON.stringify({ email, firstname, name }), // Ajout des nouveaux champs  
                          });  
          
                          if (!response.ok) {  
                              throw new Error("Erreur lors de l'inscription");  
                          }  
          
                          handleDownload(); // Télécharge la brochure  
          
                          Swal.fire({  
                              icon: 'success',  
                              title: 'Inscription réussie!',  
                              text: 'Vous êtes maintenant abonné à notre newsletter!',  
                          });  
                      } catch (error) {  
                          console.error(error); // Affiche l'erreur dans la console pour le débogage  
                          Swal.fire({  
                              icon: 'error',  
                              title: 'Erreur',  
                              text: error.message,  
                          });  
                      }  
                  }  
              } else {  
                  console.log("Popup annulé, aucune information saisie."); // Optionnel : log pour le débogage  
              }  
          };    
          const handleDownload = () => {  
              // Crée un lien temporaire pour le téléchargement  
              const link = document.createElement('a');  
              link.href = brochure;  
              link.download = 'Veille Media.pdf'; 
              document.body.appendChild(link);  
              link.click();  
              document.body.removeChild(link);  
          };

  return (
    <div>
        <div className='strategic'>
          <div className='container'>
              <div className='strategic__title d-flex justify-content-center mt-3 mb-3'>
                  <h2>Veille stratégique</h2>
              </div>
              <div className='strategic__descrption d-flex justify-content-center mb-3'>
                <p>Surveillez votre environnement d'affaires et restez informé des tendances et des évolutions qui peuvent impacter votre entreprise.</p>
              </div>
          </div>
          <div className='container'>

          <div className='strategic__content d-flex justify-content-center'>
              <div className='strategic__content_image d-flex justify-content-end'>
                <img src={imgStrategic} alt="" />
              </div>
              <div className='strategic_content_bloc'>
                <div className='strategic_content_bloc-title mb-3'>
                  <h3>Veille médiatique</h3>
                </div>
                <div className='strategic_content_bloc-desc mb-4'>
                  <p>Nous offrons des services de veille stratégique pour une prise de décision proactive.</p>
                </div>
                <div className='strategic_content_bloc-list'>
                    <ul>
                      <li><i class="fa-regular fa-check-circle"></i> <strong>Veille Concurrentielle : </strong> Surveillance des activités de vos concurrents pour identifier des opportunités et des menaces.</li>
                      <li><i class="fa-regular fa-check-circle"></i> <strong>Veille Technologique : </strong>Suivi des avancées technologiques pertinentes pour votre secteur.
                      </li>
                      <li><i class="fa-regular fa-check-circle"></i> <strong>Veille Média : </strong> Surveillance des mentions de votre entreprise et de vos produits dans les médias pour gérer votre réputation
                      </li>
                      <li><i class="fa-regular fa-check-circle"></i> <strong>Rapports de Veille : </strong>Élaboration de rapports réguliers et détaillés pour vous fournir des informations pertinentes et à jour.
                      </li>
                    </ul>
                </div>
                <div className='strategic_content_bloc-desc'>
                    <p>En collaboration avec nos partenaires, data360degree met à la disposition de ses clients un service de veille stratégique nommé NUNGA SCAN MEDIAS. Basé sur une plateforme intelligente de veille médiatique et de collecte d'informations, il collecte, stocke et analyse les données de médias locaux et internationaux.</p>
                </div>
              </div>
          </div>
          <div className='global__buttons strategic__buttons mt-4 d-flex justify-content-center'>
            <button onClick={toggleModal}>Demandez une consultation</button>
            <button onClick={handleSubscribe} className='blochure'>Télécharger la brochure</button>
          </div>
          </div>
        </div>
        <section className='medias'>
          <div className="container">
                <div className='medias__title d-flex justify-content-center'>
                    <h2>Nunga scan médias</h2>
                </div>
                <div className='medias__desc d-flex justify-content-center mb-3'>
                    <p>Grâce à NUNGA SCAN, vous bénéficiez d'une vision globale de votre présence médiatique et des tendances de votre secteur.</p>
                </div>
            </div>
            <div className='container'>
                <div className='medias__cards d-flex justify-content-center'>
                  <article className='medias__card'>
                    <div className='card__icon'>
                    <button>
                      <i class="fas fa-chart-pie"></i>
                    </button>
                    </div>
                    <div className='card__title'>
                      <h3>Technologie de pointe</h3>
                    </div>
                    <div className='card__list'>
                      <ul>
                        <li><i class="fas fa-check"></i><strong>Intelligence artificielle : </strong><br /> Utilisation de l'IA pour la transcription automatique et le traitement des données.</li>
                        <li><i class="fas fa-check"></i><strong>Surveillance continue : </strong><br /> Monitorer en temps réel les mentions et les discussions autour de votre marque.</li>
                      </ul>
                    </div>
                  </article>
                  <article className='medias__card'>
                    <div className='card__icon'>
                    <button>
                      <i class="fas fa-chart-pie"></i>
                    </button>
                    </div>
                    <div className='card__title'>
                      <h3>Fonctionnalités</h3>
                    </div>
                    <div className='card__list'>
                      <ul>
                        <li><i class="fas fa-check"></i>  <strong>Transcription automatique : </strong><br /> Conversion des contenus audio et vidéo en texte pour une analyse facile.</li>
                        <li><i class="fas fa-check"></i>  <strong>Transcription automatique : </strong><br /> Analyse des données médias Extraction des insights clés à partir des données collectées</li>
                        <li><i class="fas fa-check"></i>  <strong>Alertes personnalisées : </strong><br /> Recevoir des notifications en temps réel sur les mentions importantes.</li>
                      </ul>
                    </div>
                  </article>
                  <article className='medias__card'>
                    <div className='card__icon'>
                    <button>
                      <i class="fas fa-chart-pie"></i>
                    </button>
                    </div>
                    <div className='card__title'>
                      <h3>Utilités</h3>
                    </div>
                    <div className='card__list'>
                      <ul>
                        <li><i class="fas fa-check"></i><strong>Contrôle de réputation : </strong><br /> Surveiller et gérer l'image de votre entreprise dans les médias.</li>
                        <li><i class="fas fa-check"></i><strong>fficacité de la communication : </strong><br />Mesurer l'impact de vos campagnes de communication</li>
                        <li><i class="fas fa-check"></i><strong>Surveillance sectorielle : </strong><br /> Garder un œil sur les tendances et les concurrents de votre secteur.</li>
                      </ul>
                    </div>
                  </article>
                </div>
                </div>
        </section>
        <div className='rapport'>
          <div className='container'>
              <div className='rapport__content d-flex justify-conetent-center'>
                <div className='rapport__content_image'>
                  <img src={rapportImg} alt="" />
                </div>
                <div className='rapport__content_bloc'>
                  <div className='rapport__content_bloc-title mt-5 mb-3'>
                    <h3>Obtenir des rapports sur<br /> mesure</h3>
                  </div>
                  <div className='rapport__content_bloc-desc'>
                    <p>Grâce à notre plateforme, vous pouvez obtenir<br /> des rapports sur mesure pour suivre l'évolution<br /> de vos campagnes marketing.</p>
                  </div>
                </div>
              </div>
              <div className='global__buttons rapport__buttons d-flex justify-content-center mb-5 mt-4'>
                <button onClick={showAlert}>Commandez un rapport</button>
                <button onClick={showAlert}>Télécharger un raport gratuit </button>
              </div>
          </div>
        </div>
        <section className='offer'>
            <div className='offer_title mb-2'>
              <h2>En savoir plus sur nos offres</h2>
            </div>
            <div className='offer_content mb-3'>
              <p>Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                            n'hésitez pas à nous contacter.
              </p>
            </div>
            <div className='offer_btn'>
              <button onClick={toggleModal}>Contactez-nous</button>
            </div>
      </section>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
}

export default StrategicWatch;
