import React, { useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import Vision from '../../components/Vision';
import Values from '../../components/Values';
import Mision from '../../components/Mision';
import './About.css';
import FAQ from '../../components/Faq/Faq';
import AboutImg from '../../assets/images/Image.png';
import TeamSection from '../../components/TeamSection/TeamSection';
import '../../components/TeamSection/TeamSection.css';

function About() {

    const [activeTab, setActiveTab] = useState('vision');

    return (
        <main>
            <div className="about">
                <div className="about__hero">
                    <div className='container d-flex justify-content-between'>
                        <div className="about__hero-text">
                            <h1>A propos de nous</h1>
                        </div>
                        <div className="about__hero-img">
                            <img src={AboutImg} alt="" />
                        </div>
                    </div>
                </div>
                <div className='about_description'>
                    <div className='container d-flex justify-content-center'>
                        <div className='about_description-title mb-3'>
                            <h2>Qui sommes nous</h2>
                        </div>
                    </div>
                    <div className='about_description-content d-flex justify-content-center w-100'>
                        <p className='text-center'>
                        Nous sommes une entreprise congolaise spécialisée dans la gestion globale de
                        données, fondée par un groupe d’experts passionnés de la donnée
                        </p>
                    </div>
                </div>
                <section className="full-width-bg second-section">
                    <div className='container d-flex justify-content-center'>
                        <div className="tabs">
                            <div className="title-tab">
                                <h2 className=''>Ce qui nous definit</h2>
                            </div>
                            <div className="container tab-buttons">
                                <button onClick={() => setActiveTab('vision')} className={activeTab === 'vision' ? 'active' : ''}>
                                Notre vision
                                </button>&nbsp;&nbsp;
                                <button onClick={() => setActiveTab('mision')} className={activeTab === 'mision' ? 'active' : ''}>
                                Notre mission
                                </button>&nbsp;&nbsp;
                                <button onClick={() => setActiveTab('values')} className={activeTab === 'values' ? 'active' : ''}>
                                Nos valeurs 
                                </button>
                            </div>
                            <div className="tab-content">
                                <TransitionGroup>
                                <CSSTransition key={activeTab} timeout={300} classNames="fade">
                                    {activeTab === 'vision' ? <Vision /> : activeTab === 'mision' ? <Mision /> : <Values />}
                                </CSSTransition>
                                </TransitionGroup>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='team'>
                    <div className='container d-flex justify-content-center'>
                        <TeamSection />
                    </div>
                </div>
                <div className="bloc_faq">
                    <div className='container'>
                    <div className='faq_title mb-3'>
                        <h2>FAQ</h2>
                    </div>
                    <FAQ />
                    </div>
                </div>
            {/*  <section className='section__career'>
                    <div className='container'>
                    <div className='career mb-2'>
                        <h2>Carrière</h2>
                    </div>
                    <div className='career__text mb-2'>
                        <h3>Rejoignez notre équipe</h3>
                    </div>
                    <div className='career__desc'>
                        <p>
                        Nous sommes toujours à la recherche de talents passionnés par
                        l'analyse, l'analyse deles données et l'innovation. Découvrez nos offres d'emploi et postulez dès aujourd'hui.
                        </p>
                    </div>
                    <div className='btn__career'>
                        <button>En savoir plus</button>
                    </div>
                    </div>
                </section>*/}
                <section className='offer'>
                    <div className='container'>
                        <div className='offer_title mb-2'>
                            <h2>En savoir plus sur nos offres</h2>
                        </div>
                        <div className='offer_content mb-3'>
                            <p>
                            Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                            n'hésitez pas à nous contacter.
                            </p>
                        </div>
                        <div className='offer_btn'>
                            <button>Contactez-nous</button>
                        </div>
                    </div>
                </section>
            </div>
        </main>
    )
}

export default About