import React from 'react';
import { Link } from 'react-router-dom';
import article from '../../assets/images/arcticle-1.png';
import './Article.css'

const TabContent1 = () => {
    return  (
            <div>
                <div className='last-article'>
                    <div className='img-last'>
                        <Link to='/News/detail'>
                            <img className='img__last-img' src={article} alt="" />
                        </Link>
                    </div>&nbsp;
                    <div className='title-last'>
                        <h6>Publié | 09 janvier 2025</h6>
                        <h2>Les études de Marché en Mutation...</h2>
                        <p> 
                            La RDC, un pays longtemps marqué par des défis logistiques et infrastructurels, 
                            voit aujourd'hui son paysage marketing transformé par une vague digitale...
                        </p>
                    </div>
                </div>
                <div className='last-article__button'>
                    <Link style={{ textDecoration: 'none'}}  className='d-flex justify-content-center' to='/News'>
                        <button className='last-article__button-voirplus'>Voir les autres articles</button>
                    </Link>
                </div>
                <hr className='article-insgth-hr' />
            </div>
    );
}

export default TabContent1