import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
//import image from '../../assets/images/baba.jpg';
import Modal from '../Modal';
import { useTranslation } from 'react-i18next'; 
import humburger from '../../assets/images/icon-hamburger.svg';
import logo from '../../assets/images/new-logo.png'
import { useLocation } from 'react-router-dom';

function Header() {
  const { t, i18n } = useTranslation();
  const [menuOpen, setMenuOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  //const [mobileSubMenuOpen, setMobileSubMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);
  //const toggleMobileSubMenu = () => setMobileSubMenuOpen(!mobileSubMenuOpen);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    setDropdownOpen(false);
  };
  const location = useLocation();
  return (
    <>
      <header>
        <div className='container'>
        <nav>
          <div className="figure-logo">
            <Link to="/">
              <img className='logo' src={logo} alt="" />
            </Link>
          </div>
          <div className='bloc-menu'>
            <ul className={`nav-links ${menuOpen ? 'show' : ''}`}>
              <li className={location.pathname === '/about' ? 'active' : ''}>
                <Link to="/about">À propos</Link>
              </li><hr />
              <li className={location.pathname === '/solutions' ? 'active' : ''}>
                <Link to="/solutions">Produits & services</Link>
              </li><hr />
              {/* <li className={`menu-item ${mobileSubMenuOpen ? 'mobile-open' : ''}`}>
                <Link 
                  to="" 
                  onMouseEnter={toggleMenu} 
                  onClick={toggleMobileSubMenu}
                >
                  Produits & Services
                </Link>
                <i className='fas fa-chevron-down'></i>
                <div className={`mega-menu ${menuOpen ? 'show' : ''}`} onMouseLeave={toggleMenu}>
                  <div className="column">
                    <h3>Recherche marketing</h3>
                    <Link><p>Études de marché</p></Link>
                    <Link><p>Sondages et Enquêtes</p></Link>
                    <Link><p>Identification des tendances et opportunités</p></Link>
                    <Link><p>Tests de Produit et de Concept </p></Link>&nbsp;
                    <h3>Recherche marketing</h3>
                    <Link><p>Gestion des Données et Analytics</p></Link>
                    <Link><p>Intelligence Artificielle</p></Link>
                    <Link><p>Consulting en Data Science</p></Link>
                    <Link><p>Intégration de Systèmes </p></Link>
                  </div>
                  <div className="column">
                    <h3>Études économiques</h3>
                    <Link><p>Collecte et analyse de données</p></Link>
                    <Link><p>Rapports détaillés</p></Link>&nbsp;
                    <h3>Veille Stratégique</h3>
                    <Link><p>Veille Concurrentielle </p></Link>
                    <Link><p>Veille Technologique </p></Link>
                    <Link><p>Veille Média </p></Link>
                    <Link><p>Rapports de Veille </p></Link>
                  </div>
                  <div className="column">
                    <h6 className='feactures'>Fonctionnalités</h6>
                    <Link><h2 className='title-about'>Data360degree</h2></Link>
                    <img src={image} alt=""/><br />
                    <h6 className='text-about'>Découvrez comment nos solutions innovantes peuvent propulser votre entreprise vers de nouveaux sommets.</h6>
                  </div>
                </div>
              </li><hr /> */}
              <li className={location.pathname === '/formations' ? 'active' : ''}>
                <Link to="#">Formations</Link>
              </li>
              <hr />
              <li  className={location.pathname === '/News' ? 'active' : ''}>
                <Link to="/News">Actualités</Link>
              </li>
              <div className='mobile-div'>
                <form className='form-mobile'>
                  <div className='form__search-mobile'>
                    <input placeholder='Rechercher'type="search" />
                  </div>
                </form>
                <li>
                  <Link to="">Se connecter</Link><i className="fas fa-user-circle "></i>
                </li>&nbsp; 
                <hr />
                <li className="mobile-only">
                  <button className="btn-contact-mobile" onClick={toggleModal}>Contacter-nous</button>
                </li>
              </div>
            </ul>
          </div>
          <div className="btn-header">
            {/* <div className="language-selector">
              <button onClick={toggleDropdown}>
                <i className="fas fa-globe"></i>{t('language')}
              </button>
              {dropdownOpen && (
                <ul className={`dropdown-menu ${dropdownOpen ? 'show' : ''}`}>
                  <li onClick={() => changeLanguage('en')}>English</li>
                  <li onClick={() => changeLanguage('fr')}>Français</li>
                  <li onClick={() => changeLanguage('es')}>Español</li>
                </ul>
              )}
            </div> */}
            <div htmlFor="menu-toggle" className="menu-icon" onClick={toggleMenu}>
                <img src={humburger} alt="menu icon" />
            </div>
            <input hidden type="checkbox" id="menu-toggle" checked={menuOpen} readOnly />
            <button className="btn-contact" onClick={toggleModal}>Contacter-nous</button>
          </div>
        </nav>
        </div>
      </header>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </>
  );
}

export default Header;
