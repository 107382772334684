import React, { useState } from 'react';
import './Economics.css';
import OptiminationImg from '../../../assets/images/hero.png';
import Modal from '../../../components/Modal';
import sante from '../../../assets/images/sante.png';
import economie from '../../../assets/images/economie.png';
import geo from '../../../assets/images/geo.png';
import Sociologie from '../../../assets/images/Sociologie.png';
import Anthropologie  from '../../../assets/images/Anthropologie.png';
import SearchDemo from '../../../assets/images/searchDemo.png';
import Swal from 'sweetalert2';
import brochure from '../../../assets/Brochures/scienceP.pdf';


function Economics() {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const toggleModal = () => setIsModalOpen(!isModalOpen);
        const handleSubscribe = async () => {  
            const { value } = await Swal.fire({  
                title: 'Veuillez laisser vos informations pour télécharger cette brochure',  
                html: `  
                    <input id="swal-input1" class="swal2-input" style="width: 100%; max-width: 400px;" placeholder="Votre prénom">  
                    <input id="swal-input2" class="swal2-input"  style="width: 100%; max-width: 400px;" placeholder="Votre nom">  
                    <input id="swal-input3" type="email" class="swal2-input"  style="width: 100%; max-width: 400px;" placeholder="Votre adresse email">  
                `,  
                focusConfirm: false,  
                showCancelButton: true,  
                confirmButtonText: 'Valider',  
                cancelButtonText: 'Annuler',
                customClass: {  
                    title: 'swal-title', // Classe pour le titre  
                    input: 'swal-input'  // Classe pour les inputs  
                },    
                preConfirm: () => {  
                    const email = document.getElementById('swal-input3').value;  
                    const firstname = document.getElementById('swal-input1').value;  
                    const name = document.getElementById('swal-input2').value;  
        
                    if (!email) {  
                        Swal.showValidationMessage('Veuillez entrer une adresse email!');  
                    }  
                    return { email, firstname, name };  
                },
                onBeforeOpen: () => {  
                    // Appliquer le style à l'élément titre  
                    const titleElement = document.querySelector('.swal-title');  
                    if (titleElement) {  
                        titleElement.style.fontSize = '10px'; // Ajustez à la taille souhaitée  
                        titleElement.style.marginBottom = '10px'; // Marge en bas si nécessaire  
                        titleElement.style.fontWeight = 'normal'; // Ajuster le poids de la police si besoin  
                    }  
                },  
            });   
        
            // Vérifiez si value est défini avant de déstructurer  
            if (value) {  
                const { email, firstname, name } = value;  
                console.log("Informations saisies:", { email, firstname, name }); // Vérifiez ici  
        
                if (email) {  
                    try {  
                        const response = await fetch('https://backend.actu24.cd/public/newsletter/subscriber', {  
                            method: 'POST',  
                            headers: {  
                                'Content-Type': 'application/json',  
                            },  
                            body: JSON.stringify({ email, firstname, name }), // Ajout des nouveaux champs  
                        });  
        
                        if (!response.ok) {  
                            throw new Error("Erreur lors de l'inscription");  
                        }  
        
                        handleDownload(); // Télécharge la brochure  
        
                        Swal.fire({  
                            icon: 'success',  
                            title: 'Inscription réussie!',  
                            text: 'Vous êtes maintenant abonné à notre newsletter!',  
                        });  
                    } catch (error) {  
                        console.error(error); // Affiche l'erreur dans la console pour le débogage  
                        Swal.fire({  
                            icon: 'error',  
                            title: 'Erreur',  
                            text: error.message,  
                        });  
                    }  
                }  
            } else {  
                console.log("Popup annulé, aucune information saisie."); // Optionnel : log pour le débogage  
            }  
        };    
        const handleDownload = () => {  
            // Crée un lien temporaire pour le téléchargement  
            const link = document.createElement('a');  
            link.href = brochure;  
            link.download = 'science politique.pdf'; 
            document.body.appendChild(link);  
            link.click();  
            document.body.removeChild(link);  
        };
  return (
    <div>
        <div className='objectif'>
            <div className='objectif__description'>
                <div className='container'>
                <p>Notre objectif est de comprendre les dynamiques démographiques et les caractéristiques de la population humaines en République Démocratique du Congo et dans la région.</p>
            </div>
            </div>
                <div className='economic'>
                    <div className="container d-flex justify-content-between">
                        <div className='economic__content'>
                            <div className='economic__content_title'>
                            <h3>Récheche démographique</h3>
                            </div>
                            <div className='economic__content_title'>
                            <p>Nous étudions la populations humaines de leur taille, de leur structure par âge et par sexe, de leur répartition géographique, de leur croissance et de leurs mouvements.</p>
                            </div>
                            <div className='objectif__content_list'>
                                <ul>
                                    <li><i class="fa-regular fa-check-circle"></i> Naissance</li>
                                    <li><i class="fa-regular fa-check-circle"></i> Décès</li>
                                    <li><i class="fa-regular fa-check-circle"></i> Migrations</li>
                                </ul>
                            </div>
                            <div className='global__buttons economic__content_buttons'>
                                <button onClick={toggleModal}>Demandez une consultation</button>
                                <button onClick={handleSubscribe}>Télécharger Brochure</button>
                            </div>
                        </div>
                        <div className='econmic__image mt-5'>
                            <img src={SearchDemo} alt="" />
                        </div>
                    </div>
                </div>
        </div>
        <div className='health'>
            <div className="container d-flex  justify-content-between">
                <div className='health_image'>
                    <img src={sante} alt="" />
                </div>
                <div className='health_content'>
                    <div className='health_content_title'>
                      <h3>Santé publique :</h3>
                    </div>
                    <div className='health_content_description'>
                        <p>Nous étudions la santé des populations et des facteurs qui l'influencent. Nous nous intéressons notamment aux inégalités en matière de santé, aux déterminants sociaux de la santé et aux politiques de santé publique.</p>
                    </div>
                    <div className='global__buttons health_content_buttons'>
                      <button onClick={toggleModal}>Demandez une consultation</button>
                      <button onClick={handleSubscribe}>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='economic'>
              <div className="container d-flex justify-content-between">
                  <div className='economic__content'>
                      <div className='economic__content_title'>
                          <h3>Économie de la population</h3>
                      </div>
                      <div className='economic__content_title'>
                        <p>
                          Nous étudions des liens entre les variables économiques et les phénomènes démographiques. Nous examinons aussi les effets des politiques économiques sur la croissance démographique, l'emploi, les revenus, etc.
                        </p>
                      </div>
                      <div className='global__buttons economic__content_buttons'>
                          <button onClick={toggleModal}>Demandez une consultation</button>
                          <button onClick={handleSubscribe}>Télécharger Brochure</button>
                      </div>
                  </div>
                  <div className='econmic__image'>
                    <img src={economie} alt="" />
                  </div>
              </div>
        </div>
        <div className='population'>
            <div className='container d-flex justify-content-between'>
                <div className='population__image'>
                  <img src={geo} alt="" />
                </div>
                <div className='population__content'>
                    <div className='population__content_title'>
                      <h3>Géographie de la population</h3>
                    </div>
                    <div className='population__content_description'>
                        <p>Nous analysons la répartition spatiale des populations, des facteurs qui influencent cette répartition et des conséquences géographiques des phénomènes démographiques.</p>
                    </div>
                    <div className='global__buttons population__content_buttons'>
                      <button onClick={toggleModal}>Demandez une consultation</button>
                      <button onClick={handleSubscribe}>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        <div className='Anthropologie'>
            <div className='container d-flex justify-content-between'>
                <div className='Anthropologie__content'>
                    <div className='Anthropologie__content_title'>
                      <h3>Anthropologie de la population</h3>
                    </div>
                    <div className='Anthropologie__contentent_description'>
                      <p>Nous étudions des populations sous l'angle culturel et historique. En s'intéressant aux systèmes de valeurs, aux croyances, aux pratiques sociales et aux représentations qui influencent les comportements démographiques.</p>
                    </div>
                    <div className='global__buttons Anthropologie__contentent_buttons'>
                        <button onClick={toggleModal}>Demandez une consultation</button>
                        <button onClick={handleSubscribe}>Télécharger Brochure</button>
                    </div>
                </div>
                <div className='Anthropologie__image'>
                  <img src={Anthropologie} alt="" />
                </div>
            </div>
        </div>
        <div className='Sociologie'>
            <div className='container d-flex justify-content-between'>
                <div className='Sociologie__image'>
                  <img src={Sociologie} alt="" />
                </div>
                <div className='Sociologie__content'>
                    <div className='Sociologie__content_title'>
                        <h3>Sociologie de la population:</h3>
                    </div>
                    <div className='Sociologie__content_description'>
                        <p>Nous analysons des relations entre les phénomènes démographiques et les processus sociaux, culturels et économiques. Elle s'intéresse notamment aux comportements reproductifs, aux modes de vie, aux inégalités sociales et à leur impact sur la dynamique démographique.</p>
                    </div>
                    <div className='global__buttons Sociologie__content_buttons'>
                        <button onClick={toggleModal}>Demandez une consultation</button>
                        <button onClick={handleSubscribe}>Télécharger Brochure</button>
                    </div>
                </div>
            </div>
        </div>
        {/* <section className='know-more'> 
            <div className='know-more__title'>
                                <h4>En savoir plus sur nos offres</h4>
            </div>
            <div className='know-more_description'>
                                <p>Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, n'hésitez pas à nous contacter.</p>
            </div>
            <div className='know-more__button'>
                                <button>Contactez-nous</button>
            </div>
        </section> */}
          <section className='offer'>
              <div className="container">
                    <div className='offer_title mb-2'>
                        <h2>En savoir plus sur nos offres</h2>
                    </div>
                    <div className='offer_content mb-3'>
                        <p>
                        Pour en savoir plus sur nos services ou pour discuter de vos besoins spécifiques, 
                        n'hésitez pas à nous contacter.
                        </p>
                    </div>
                    <div className='offer_btn'>
                        <button onClick={toggleModal}>Contactez-nous</button>
                    </div>
                  </div>
            </section>
      <Modal isOpen={isModalOpen} onClose={toggleModal} />
    </div>
  );
}

export default Economics;
