import './News.css';
import React, { useState } from 'react';
//import { Link } from 'react-router-dom';
import NewsImg from '../../assets/images/Image.png';
import Actu from './Actu/Actu';
import Event from './Event/Event';
import Webinar from './Webinar/Webinar';
import Report from './Report/Report';
import Article from './Article/Article';

function News() {
    const [activeLink, setActiveLink] = useState('actu'); // Gestion de l'onglet actif

    // Fonction pour afficher le contenu dynamique en fonction de l'onglet sélectionné
    // Function to display dynamic content based on selected tabs   
    const renderContent = () => {
        switch (activeLink) {
            case 'actu':
                return <Actu />;
            case 'report':
                return <Report />;
            case 'event':
                return <Event />;
            case 'webinar':
                return <Webinar />;
            case 'article':
                return <Article />;
            default:
                return <Actu />;
        }
    };

    return (
        <div className="news">
            <div className="news__hero mt-5">
                <div className='container d-flex justify-content-between'>
                    <div className="news__hero-text">
                        <h1>Actualités & événements</h1>
                    </div>
                    <div className="news__hero-img mt-5">
                        <img src={NewsImg} alt="Actualités" />
                    </div>
                </div>
            </div>
            <div className="news__nav">
                <div className="d-flex justify-content-center">
                    <button className={`news__nav-button ${activeLink === 'actu' ? 'active' : ''}`} onClick={() => setActiveLink('actu')}>
                    Tout
                    </button>

                    <button 
                    className={`news__nav-button ${activeLink === 'article' ? 'active' : ''}`} 
                    onClick={() => setActiveLink('article')}
                    >
                    Articles
                    </button>

                    <button 
                    className={`news__nav-button ${activeLink === 'event' ? 'active' : ''}`} 
                    onClick={() => setActiveLink('event')}
                    >
                    Événements
                    </button>

                    <button 
                    className={`news__nav-button ${activeLink === 'report' ? 'active' : ''}`} 
                    onClick={() => setActiveLink('report')}
                    >
                    Rapports
                    </button>

                    <button 
                    className={`news__nav-button ${activeLink === 'webinar' ? 'active' : ''}`} 
                    onClick={() => setActiveLink('webinar')}
                    >
                    Webinaires
                    </button>
                </div>
            </div>
            <div className="news__content">
                {renderContent()}
            </div>
        </div>
    );
}
export default News;
